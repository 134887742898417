<template>
  <div>
    <div v-if="article">
      <div class="article-items-count">
        <counter-label :counter="article.items.length" label="exemplaire"></counter-label>
      </div>
      <ul
        class="article-item-detail"
        v-for="item of article.items"
        :key="item.id"
        :class="{ 'item-borrowed': isBorrowed(item) }"
      >
        <li class="article-title">
          <span v-if="item.reference">
            <b><i class="fa fa-barcode"></i> {{ item.reference }}</b>
          </span>
        </li>
        <li v-if="isBorrowed(item)">
          <b><i class="fa fa-info-circle"></i> Emprunté</b><br />
          par {{ getBorrowing(item).entity.name }}<br />
          le {{ getBorrowing(item).borrowedOn | dateToString }}<br />
          à rendre le {{ getBorrowing(item).returnExpectedOn | dateToString }}
          <span v-if="getBorrowing(item).hasPenalties()" class="badge badge-danger">
            pénalités
          </span>
          <span v-else-if="getBorrowing(item).isLate()" class="badge badge-dark">retard</span>
        </li>
        <li v-if="isBorrowed(item)">
          <a class="btn btn-primary btn-block" @click.prevent="returnBorrow(item)">
            <i class="fa fa-rotate-left"></i> Rendre
          </a>
          <a class="btn btn-secondary btn-sm btn-block" @click.prevent="cancelBorrow(item)">
            <i class="fa fa-close"></i> Annuler le prêt
          </a>
        </li>
        <li v-else-if="canBorrow">
          <a
            :class="{ 'btn-disabled': !entity, }"
            :title="!entity ? 'Sélectionnez la famille qui effectue l\'emprunt' : ''"
            v-b-tooltip
            class="btn btn-primary btn-block"
            @click.prevent="borrow(item)"
          >
            <i class="fa fa-rotate-right"></i> Emprunter
          </a>
        </li>
        <li v-if="!item.isAvailable">
          <b><i class="fa fa-warning"></i> Indisponible</b>
        </li>
        <li v-if="item.location.id">
          <b><i class="fa fa-magnifying-glass-location"></i> Emplacement<br /></b>
          {{ item.location.name }}
        </li>
        <li v-if="item.status">
          <b><i class="fa fa-eye"></i> État<br /></b>
          {{ item.status }}
        </li>
        <li v-if="item.description">
          <b><i class="fa fa-list"></i> Description<br /></b>
          {{ item.description }}
        </li>
        <li v-if="!isAllShown(item) && hasMore(item)">
          <a href @click.prevent="showMore(item)">
            <i class="fa fa-ellipsis-h"></i> Voir plus
          </a>
        </li>
        <li v-if="item.provider.id && isAllShown(item)">
          <b><i class="fa fa-shop"></i> Fournisseur<br /></b>
          {{ item.provider.name }}
        </li>
        <li v-if="item.boughtOn && isAllShown(item)">
          <b><i class="fa fa-calendar-day"></i> Date d'achat<br /></b>
          {{ item.boughtOn | dateToString }}
        </li>
        <li v-if="item.buyingPrice && isAllShown(item)">
          <b><i class="fa fa-euro"></i> Prix d'achat<br /></b>
          {{ item.buyingPrice | currency }}
        </li>
      </ul>
    </div>
    <borrow-modal
      :item="selectedItem"
      :article="article"
      :entity="entity"
      :entity-types="entityTypes"
      :adhesion="adhesion"
      :initial-entity-type="initialEntityType"
      @done="onBorrowingChanged()"
    ></borrow-modal>
    <return-borrow-modal
      :item="selectedItem"
      :article="article"
      :borrowing="selectedBorrowing"
      @done="onBorrowingChanged()"
    ></return-borrow-modal>
    <cancel-borrow-modal
      :item="selectedItem"
      :article="article"
      :borrowing="selectedBorrowing"
      @done="onBorrowingChanged()"
    ></cancel-borrow-modal>
  </div>
</template>

<script>
import { BackendMixin } from '@/mixins/backend'
import CounterLabel from '@/components/Controls/CounterLabel.vue'
import { BackendApi } from '@/utils/http'
import { makeBorrowing } from '@/types/store'
import BorrowModal from '@/components/Store/BorrowModal.vue'
import ReturnBorrowModal from '@/components/Store/ReturnBorrowModal.vue'
import CancelBorrowModal from '@/components/Store/CancelBorrowModal.vue'

export default {
  name: 'ArticleItemsInfo',
  mixins: [BackendMixin],
  components: {
    ReturnBorrowModal,
    CancelBorrowModal,
    BorrowModal,
    CounterLabel,
  },
  props: {
    article: Object,
    entity: Object,
    entityTypes: [],
    initialEntityType: Object,
    adhesion: Object,
    canBorrow: Boolean,
  },
  data() {
    return {
      borrowings: [],
      showAll: new Map(),
      selectedItem: null,
      selectedBorrowing: null,
    }
  },
  computed: {
  },
  watch: {
    article() {
      this.loadArticleBorrowings()
    },
    canBorrow() {
    },
  },
  mounted() {
    this.loadArticleBorrowings()
  },
  methods: {
    async loadArticleBorrowings() {
      if (this.article) {
        const backendApi = new BackendApi(
          'get',
          '/store/api/current-article-borrowings/' + this.article.id + '/'
        )
        try {
          let resp = await backendApi.callApi()
          this.borrowings = resp.data.map(elt => makeBorrowing(elt))
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
      }
    },
    async borrow(item) {
      if (this.entity) {
        this.selectedItem = item
        this.$nextTick(
          function() {
            this.$bvModal.show('bv-modal-borrow-article' + item.id)
          }
        )
      }
    },
    async returnBorrow(item) {
      this.selectedItem = item
      this.selectedBorrowing = this.getBorrowing(item)
      this.$nextTick(function() {
        this.$bvModal.show('bv-modal-return-borrow-article' + item.id)
      })
    },
    async cancelBorrow(item) {
      this.selectedItem = item
      this.selectedBorrowing = this.getBorrowing(item)
      this.$nextTick(function() {
        this.$bvModal.show('bv-modal-cancel-borrow-article' + item.id)
      })
    },
    isBorrowed(item) {
      return this.borrowings.map(elt => elt.item.id).indexOf(item.id) >= 0
    },
    getBorrowing(item) {
      const index = this.borrowings.map(elt => elt.item.id).indexOf(item.id)
      if (index >= 0) {
        return this.borrowings[index]
      }
      return null
    },
    isAllShown(item) {
      return this.showAll.has(item.id)
    },
    hasMore(item) {
      return item.boughtOn || item.buyingPrice || item.provider.id
    },
    showMore(item) {
      this.showAll.set(item.id, item)
      this.showAll = new Map(this.showAll)
    },
    onBorrowingChanged() {
      this.loadArticleBorrowings()
      this.$emit('reload', {})
    },
  },
}
</script>

<style scoped lang="less">
  .article-items-count {
    font-weight: bold;
    padding: 5px;
    background: #eee;
    color: #222;
  }
  ul.article-item-detail {
    list-style: none;
    margin: 0 0 20px 0;
    padding: 5px;
    background: #eee;
    color: #222;
  }

  ul.article-item-detail li.article-title {
    background: #454851;
    color: #fff;
  }

  ul.article-item-detail.item-borrowed li.article-title {
    background: #fe5757;
    color: #fff;
  }

  ul.article-item-detail li {
    padding: 4px;
    margin-bottom: 4px;
    border-bottom: solid 1px #ccc;
  }

  ul.article-item-detail.item-borrowed li {
    background: #ffe9b6;
    color: #222;
  }

  ul.article-item-detail li:last-of-type {
    margin-bottom: 0;
    border-bottom: none;
  }
  .dark-mode {
    .article-items-count {
      background: #444;
      color: #fff;
    }
    ul.article-item-detail {
      background: #444;
      color: #fff;
    }
    ul.article-item-detail li.article-title {
      background: #222;
      color: #fff;
    }
  }
</style>
